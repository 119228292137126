import * as React from "react";
import {
  CallToAction,
  Layout,
  MainBackground,
  ServicesMenuSection,
  SpecialHeader,
} from "../components/containers";
import { Button } from "../components/utility";
import { Product_Routes } from "../data/Routes";

import Worktogether from "../images/worktogether.jpeg";
import Win from "../svgs/company/Win.svg";
import Got from "../svgs/company/Got.svg";
import Partner from "../svgs/company/Partner.svg";
import Secure from "../svgs/company/Secure.svg";
import SEO from "../components/marketing/Seo";
import { About_Meta } from "../data/MetaData";

const WorkWithUs = () => {
  return (
    <Layout>
      <SEO title={About_Meta.title} description={About_Meta.description} />
      <SpecialHeader
        src={Worktogether}
        title="Work with us"
        to={Product_Routes.Contactus}
      />

      <MainBackground>
        <section className="about-content">
          <div className="about-wrapper">
            <div className="about-title-container">
              <h1>Want to grow your business with more leads?</h1>
              <p>
                We connect our suppliers with customers who specifically match
                that service. That means we bring you quality leads that convert
                to sales.
                <br />
                Customers get the perfect products for their needs. You make
                more sales. And by being the middle man, we help all Australian
                businesses thrive. It’s a win-win-win!
              </p>
            </div>
            <div className="points-container">
              <div className="point-card">
                <img src={Win} alt="Win win business" />
                <h2>We help all Australian businesses thrive</h2>
              </div>
              <div className="point-card">
                <img src={Got} alt="We connect" />
                <h2>Convert to sales</h2>
              </div>
              <div className="point-card">
                <img src={Partner} alt="Free Service" />
                <h2>Work with us</h2>
              </div>
              <div className="point-card">
                <img src={Secure} alt="Free Service" />
                <h2>Qualified Providers And First-Class Products</h2>
              </div>
            </div>
            <Button to={Product_Routes.Contactus}>Get in touch with us</Button>
          </div>
        </section>
        <ServicesMenuSection />
        <CallToAction />
      </MainBackground>
    </Layout>
  );
};

export default WorkWithUs;
